<script>
var qs = require('qs');
export default {
    data() {
        return {
            currentClientBonuses: null,
            paymentPrompt: false,
            paymentTypes: [],
            paymentType: null,
            paymentAmount: null
        }
    },
    props: { 
        order: {
            Type: Object,
            default: null
        },
        callback: {
            Type: Function,
            default: null
        },
        acceptText: {
            Type: String,
            default: 'Оплатить'
        }
    },
    watch: {
        paymentAmount(newVal) {
            this.paymentAmount = Math.round(newVal * 100) / 100;        
        },
        paymentAmountLeft() {
            if (this.paymentAmountLeft === 0) {
                this.paymentPrompt = false;
            }
        }
    },
    computed: {
        currentClientBonusesBalance() {
            // return this.$store.state.activeClient.info.bonus;
            return this.currentClientBonuses;
        },
        orderTotalPrice() {
            return this.$store.getters['eCommerce/orderTotalPrice'](this.order);
        },
        paymentMethodsTotalAmount() {
            let total = 0;
            if (this.currentClientBonusesBalance >= this.orderTotalPrice) {
                total = this.orderTotalPrice;
            } 
            // else if (this.currentClientBonusesBalance < this.orderTotalPrice) {
            //     total = this.currentClientBonusesBalance;
            // }
            if (this.paymentMethods) {
                this.paymentMethods.forEach(element => { total += element.payment_amount; });
            }
            return total;
        },
        paymentAmountLeft() {
            let amount = this.orderTotalPrice - this.paymentMethodsTotalAmount;
            return Math.round(amount * 100) / 100;
        },
        paymentMethods() {
            let payments = this.order ? this.order.payments : false;
            return payments;
        },
        paymentActionText() {
            return this.paymentAmountLeft > 0 ? "Добавить оплату" : this.acceptText;
        }
    },
    asyncComputed: {
        async isEditPaymentsAllowed() {
            return await this.$store.dispatch('isEditPaymentsAllowed', this.order);
        },
        async isRemovePaymentsAllowed() {
            return await this.$store.dispatch('isRemovePaymentsAllowed', this.order);
        }
    },
    methods: {
        paymentMethodName(item) {
            let paymentType = this.paymentTypes.find((el) => el.id == item.payment_type);
            return paymentType ? paymentType.text : ''; 
        },
        async paymentAction() { // срабатывает при клике на кнопку
            if (this.paymentAmountLeft > 0) {
                this.paymentAmount = this.paymentAmountLeft;
                this.paymentPrompt = true;
                console.log(this.paymentMethods);
            } else {
                this.fixPayments();
            }
        },
        async addPaymentMethod() {
            if (this.paymentAmount === 0) return;
            let payment_type = this.paymentType.id ? this.paymentType.id : 84728;
            let payment = {
                payment_amount: this.paymentAmount,
                payment_type: payment_type,
                manager_id: await this.$store.state.auth.managerId(),
                payment_time: Date.now()
            };
            console.log(payment);
            this.$store.dispatch('eCommerce/addOrderPayment', {payment: payment, order: this.order});
            this.paymentAmount = this.paymentAmountLeft;
            this.paymentType = this.paymentTypes[0];
        },
        removePaymentMethod(index) {
            this.$store.dispatch('eCommerce/removeOrderPayment', {index: index, order: this.order});
        },
        async fixPayments() {
            console.log('payments_fixed');
            // this.addPaymentMethod();
            // Удаляем тарифы с item_amount = 0, если закрываем оплаты по заказу-время
            if (this.order.is_time_order) {
                this.$store.dispatch('eCommerce/removeEmptyTariffItems', this.order);
            }

            let callback = () => {
                this.$vs.notify({
                    title: 'Успех',
                    text: 'Оплата зафиксирована',
                    color: 'success',
                    iconPack: 'feather',
                    icon: 'icon-check'
                });
                this.callback();
            };
            this.addRefererBonus();
            await this.$store.dispatch('eCommerce/updateOrderStatus', { 
                                    status: 2, 
                                    order: this.order, 
                                    callback: callback 
                                });
            // this.addRefererBonus();
        }, 
        addRefererBonus() {
            let wastePayment = 0;
            if (this.paymentMethods.length != 0) {
                (this.paymentMethods).forEach(function(item) {
                    if (item.payment_type != 1805 && item.payment_type != 1806) {
                        wastePayment = wastePayment + item.payment_amount;
                    }
                })
            }
            let data = {
                'data': {
                    'order_number': this.order.number,
                    'order_price': this.$store.getters['eCommerce/orderTotalPrice'](this.order) - wastePayment,
                    'customer_id': this.$store.state.activeClient.info.id,
                }
            }
            this.$http({
                method: 'post',
                url: '/udata/users/add_referer_bonus/.json',
                data: qs.stringify(data),
                withCredentials: true
            }).then((response) => {

            })
        },
        checkCustomerBonus() {
            let data = {
                'data': {
                    'customer_id': this.$store.state.activeClient.info.id,
                }
            }
            this.$http({
                method: 'post',
                url: '/udata/users/get_customer_bonuses/.json',
                data: qs.stringify(data),
            }).then((response) => {
                if (response.data.customer_bonuses) {
                    this.currentClientBonuses = response.data.customer_bonuses;
                    console.log(this.currentClientBonuses);
                }
            });
        },
        debitCustomerBonus() {
            let debit_bonus;
            if (this.$store.state.activeClient.info.bonus == 0) {
                debit_bonus = 0;
            } else if (this.$store.state.activeClient.info.bonus != 0 && this.$store.state.activeClient.info.bonus >= this.paymentAmount) {
                debit_bonus = this.paymentAmount;
            } else if (this.$store.state.activeClient.info.bonus != 0 && this.$store.state.activeClient.info.bonus < this.paymentAmount) {
                // debit_bonus = this.$store.state.activeClient.info.bonus;
                debit_bonus = 0;
            }
            let data = {
                'data': {
                    'customer_id': this.$store.state.activeClient.info.id,
                    'debit_bonus': debit_bonus,
                }
            }
            this.$http({
                method: 'post',
                url: '/udata/users/debit_customer_bonus/.json',
                data: qs.stringify(data),
                withCredentials: true
            }).then((response) => {
                if (response.data.result) {
                    console.log(response.data.customer_new_bonus_balance);
                }
            })
        },
    },
    beforeCreate() {
    let data = {
            'data': {
                'customer_id': this.$store.state.activeClient.info.id,
            }
        }
    this.$http({
        method: 'post',
        url: '/udata/users/get_customer_bonuses/.json',
        data: qs.stringify(data),
        }).then((response) => {
            if (response.data.customer_bonuses) {
                this.currentClientBonuses = response.data.customer_bonuses;
                console.log(this.currentClientBonuses);
            }
        });
    },
    mounted() {
    },
    created() {
        if (this.order == null) {
            this.$router.push('/');
        }

        //СПРАВОЧНИК "СПИСОК СПОСОБОВ ОПЛАТЫ TIMER"
        fetch("/udata/data/getGuideItems/''/151.json")
            .then(response => response.json())
            .then(response => {
                this.paymentTypes = Object.values(response.items.item);
                this.paymentType = this.paymentTypes[0];
            });
    }
}
</script>
